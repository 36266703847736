

/*
  Editable Display Form

  Intended for use in areas where we want to display data that can be edited
*/
form.editable-display-form {
  label {
    font-weight: 500;
  }

  input {
    &:read-only {
      opacity: 1;
      background-color: $white;
      border-color: $white;
      color: $black;
    }
    &:disabled {
      opacity: 0.8;
      background-color: rgb(225, 225, 225);
      border-color: #6e6b7b;
    }
    &:focus {
      box-shadow: none;
    }
  }

  .form-calendar {
    border-color: #d8d6de !important;
  }

}

.rdt_Table {
  min-height: 250px;
}
