
.number-plate {
  letter-spacing: 2px;
  font-weight: bold;
  padding: 3px 10px;
  border-radius: 4px;
  color: #5e5873;
  border: 1px solid #cccc;
  position: relative;
  text-transform: uppercase;
}

.change-labels {
  font-weight: bold;
}
